<!--
 * @Author: liyao
 * @Date: 2021-05-26 16:09:16
 * @LastEditTime: 2021-06-08 14:39:48
 * @LastEditors: Please set LastEditors
 * @Description: 内容引擎分组
 * @FilePath: \hxb-companyd:\workplace\qy_wx_h5\src\views\fastReply\group.vue
-->
<template>
<div id="group_wrapper" class="group_wrapper" v-slide>
  <div class="group_list">
    <p v-for="item in groupList" :key="item.id" class="group_item" :class="{active:current===item.id}" @click="handleActive(item.id)">
      {{item.name}}
    </p>
  </div>
</div>
</template>
<script>
import {messageGroup} from '@/api/fastReply'
import { reactive,toRefs } from 'vue'
import {slide} from '@/directive/slide'
export default {
  emits:['changeGroup'],
  directives:{slide},
  setup(props,context) {
     const state=reactive({
       current:null,
       groupList:[]
     })
    const getList=(keyword)=>{
      const params={
        keyword
      }
      messageGroup(params).then(res => {
        if (res&&res.body){
          state.groupList=res.body
        }else{
          state.groupList=[]
        }
        if(state.groupList&&state.groupList.length){
          handleActive(state.groupList[0].id)
        }
      })
    }
    const handleActive=(id)=>{
      state.current=id
      context.emit('changeGroup',id)
    }

    getList()
    return {
      ...toRefs(state),
      getList,
      handleActive
    }
  }
}
</script>
<style lang="less" scoped>
.group_wrapper{
  position: relative;
  width: 100%;
  overflow: hidden;
}
.group_list{
  &::-webkit-scrollbar {
    display: none;
  }
  .group_item{
    display: inline-block;
    cursor: pointer;
    color: #999;
    min-width: 120px;
    text-align: center;
    padding: 8px 24px;
    border: 1px solid #dddddd;
    border-radius: 31px;
    margin-bottom: 10px;
    display: inline-block;
    font-size: 24px;
    box-sizing: border-box;
    &:not(:last-of-type){
      margin-right: 12px;
    }
    &.active{
      color:#1678FF;
      border-color: #1678FF;
      background: rgba(22,120,255,0.10);
    }
  }
}
</style>