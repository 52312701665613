<!--
 * @Author: your name
 * @Date: 2021-05-26 15:12:23
 * @LastEditTime: 2021-06-02 20:08:01
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \hxb-companyd:\workplace\qy_wx_h5\src\components\searchInput.vue
-->
// 搜索输入框
<template>
  <div class="search_input">
    <van-search 
      v-model="keyword" 
      placeholder="搜索关键字" 
      shape="round" 
      left-icon="/imgs/icon_sousuo.png"
      @search="onSearch"
      @clear="onClear"
    />
  </div>
</template>
<script>
import { Search } from 'vant'
import { reactive,toRefs } from 'vue'
export default {
  components:{
    [Search.name]: Search
  },
  emits:['search'],
  setup(props,context) {
    const state=reactive({
      keyword:null
    })
    const onSearch=()=>{
      context.emit('search',state.keyword)
    }
    const onClear=()=>{
      context.emit('search','')
    }
    return {
      ...toRefs(state),
      onSearch,
      onClear
    }
  }
}
</script>
<style lang="less" scoped>
  .search_input{
    position: relative;
    .search_icon{
      position: absolute;

    }
    :deep(.van-search__content){
      height: 72px;
      border-radius: 34px;
      padding: 0 32px;
      .van-cell{
        padding: 16px 0 !important;
        align-items: center;
      }
      .van-field__control{
        font-size: 28px;
        &::placeholder{
          color:#ccc;
        }
      }
      .van-badge__wrapper{
        .van-icon__image{
          width: 32px;
          height: 32px;
          margin-right: 16px;
        }
        &.van-icon-clear{
          font-size: 28px;
        }
      }
    }
  }
</style>